import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Why Composites Are Fast-Replacing Traditional Materials"
            description="There are many benefits to composite materials and they’re quickly becoming favored in the auto and aerospace industries."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="Why Composites Are Fast-Replacing Traditional Materials Such As Steel and Aluminum"
            date="July 26, 2021"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     Manufacturing continues to see the benefits of composite materials used by themselves or in combination with traditional building
                     materials, such as steel and aluminum. Each year, <a href="https://idicomposites.com/markets-overview.php"> industries</a> use
                     more and more composite materials to enhance designs and bolster sales because of four primary benefits.
                  </p>

                  <h2>1. Composites Are Lighter and Stronger Than Traditional Materials</h2>
                  <p>
                     Carbon fiber composites are quickly becoming a favored material in both the auto and aerospace industries because the material is
                     solid and lightweight. When viewed purely on a <a href="http://compositeslab.com/composites-compared/"> strength-to-weight</a>{" "}
                     ratio, carbon fiber weighs about a quarter of its steel counterpart and less than three-quarters of aluminum. Additionally, the
                     composite, per weight, is stiffer than both materials and has better absorption qualities.
                  </p>

                  <p>
                     Auto manufacturers and engineers can reduce a vehicle's weight by up to 60% by using composites. And that’s without losing the
                     effectiveness of crash safety measures. According to industry reports, crash safety is only improved when implementing
                     multi-layer composite laminates because they absorb more energy than single-layer steel. Finally, the cost of composites is going
                     down, meaning manufacturing improvements reduce car prices on the consumer end.
                  </p>

                  <h2>2. Composites Allow for Flexibility and Affordability of Design</h2>
                  <p>
                     Composites do not have restrictions on shaping or design. Anything a person can think of can be made using composite materials.
                     With such ingenuity, a person can create intricate and complex structures without risking its integrity because the entire piece
                     is reinforced.
                  </p>

                  <p>
                     Additionally, while steel and other conventional materials were capable of conforming to complex shapes and structures, the labor
                     required for such endeavors was costly and, in some cases, dangerous. With composites, the manufacturing process is quicker and
                     easier to maintain, meaning companies can save money in the long run.
                  </p>

                  <h2>3. Composites Are More Durable Than Traditional Materials</h2>
                  <p>
                     Most raw materials, like metals, do not hold up well to external factors, with many substances falling victim to rust and
                     corrosion. While a composite might corrode if bonded to metal parts, it tends to maintain its shape and function regardless of
                     exposure. The material is also among the strongest of most polymers, making it ideal for outdoor structures. The dimensional
                     stability of composites means most environmental factors — temperature and weather — do not phase them, allowing composite
                     structures to maintain their shape and strength.
                  </p>

                  <h2>4. Technology Integration Allows for Quicker Production</h2>
                  <p>
                     Composites were once challenging to manufacture because of the involved lay-up process. However, with technological advancements,
                     it is now possible to construct composite parts without manual labor, allowing businesses to focus on end tasks, like final
                     construction. In addition, by eliminating the complicated process and streamlining technology with production, companies can now
                     afford what many engineers would dub a superior product.
                  </p>

                  <p>
                     The advantages of composites are many, and industries are beginning to alter production setups to include the integration of
                     composite materials. While only you can determine what is right for your business, if you would like to discuss the potential of
                     composite design within your operation, contact <a href="https://www.paintedrhino.com/manufacturing-services/"> Painted Rhino</a>
                     , an expert in the field.
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/Why-Composites.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2500) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
